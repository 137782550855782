<template>
  <div id="TasanteaProductDetail">
    <MHeader />

    <div class="logo">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/tasantea_logo.png"
        alt=""
        srcset=""
      />
    </div>
    <div class="close" @click="goBack">
      <img src="../../assets/mobile/product/close.png" alt="" srcset="" />
    </div>

    <!-- 经典系列 大红袍 -->
    <div class="item_box" v-if="pid == 1">
      <div class="series_title">经典系列</div>
      <div class="product_title">大红袍</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_dahongpao.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：福建南平市松溪县</div>
          <div class="introduce_item">海拔：约600米</div>
          <div class="introduce_item">口感：兰香馥郁幽长</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">精选福建松溪优质乌龙茶品种</div>
          <div class="introduce_item">三红七绿，传统乌龙茶工艺</div>
          <div class="introduce_item">岩骨花香，持久耐泡，回甘生津</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：24g(2gx12)</div>
        </div>
      </div>
    </div>

    <!-- 经典系列 金骏眉 -->
    <div class="item_box" v-if="pid == 2">
      <div class="series_title">经典系列</div>
      <div class="product_title">金骏眉</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_jinjunmei.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：福建南平市松溪县，</div>
          <div class="introduce_item">海拔：约600米，</div>
          <div class="introduce_item">口感：花果甜香，薯香蜜韵。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">精选福建松溪野生茶树群体种</div>
          <div class="introduce_item">21世纪创新工艺小种红茶</div>
          <div class="introduce_item">金毫显露，芽头挺拔，鲜爽回甘</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：24g(2gx12)</div>
        </div>
      </div>
    </div>

    <!-- 经典系列 白牡丹 -->
    <div class="item_box" v-if="pid == 3">
      <div class="series_title">经典系列</div>
      <div class="product_title">白牡丹</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_baimudan.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：福建南平市松溪县，</div>
          <div class="introduce_item">海拔：约600米，</div>
          <div class="introduce_item">口感：鲜爽豆奶香。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">精选福建松溪福安大白茶</div>
          <div class="introduce_item">不炒不揉，自然晾晒</div>
          <div class="introduce_item">鲜爽甘醇，豆香清幽</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：24g(2gx12)</div>
        </div>
      </div>
    </div>

    <!-- 经典系列 龙井茶 -->
    <div class="item_box" v-if="pid == 4">
      <div class="series_title">经典系列</div>
      <div class="product_title">龙井茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_longjincha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：杭州市，</div>
          <div class="introduce_item">海拔：约400米，</div>
          <div class="introduce_item">口感：炒豆香。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">杭州钱塘龙井</div>
          <div class="introduce_item">谷雨春茶老龙井</div>
          <div class="introduce_item">鲜爽回甘，炒豆香</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：24g(2gx12)</div>
        </div>
      </div>
    </div>

    <!-- 经典系列 东方美人 -->
    <div class="item_box" v-if="pid == 5">
      <div class="series_title">经典系列</div>
      <div class="product_title">东方美人</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/jingdian_p_d_e.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">1500米海拔，</div>
          <div class="introduce_item">云南临沧云顶筑巢茶庄园</div>
          <div class="introduce_item">白毫乌龙茶。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">女皇赐名，美如佳人</div>
          <div class="introduce_item">熟果蜜香，醇和甜润</div>
          <div class="introduce_item">皇室钟爱，独特口感</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：20g(2gx10)</div>
        </div>
      </div>
    </div>

    <!-- 经典系列 福鼎白茶 -->
    <div class="item_box" v-if="pid == 6">
      <div class="series_title">经典系列</div>
      <div class="product_title">福鼎白茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/jingdian_p_d_f.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">700米海拔，</div>
          <div class="introduce_item">福建松溪轻发酵福鼎大白茶。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">茶香清新，滋味鲜美</div>
          <div class="introduce_item">茶性清凉，消暑降温</div>
          <div class="introduce_item">天然养颜，清新健康</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：20g(2gx10)</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 明前绿茶 -->
    <div class="item_box" v-if="pid == 7">
      <div class="series_title">高山系列</div>
      <div class="product_title">明前绿茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_mingqianlvcha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：金沙县岚头镇，</div>
          <div class="introduce_item">海拔：约1000米，</div>
          <div class="introduce_item">口感：鲜醇甘爽。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">千米海拔，贵州高山茶园</div>
          <div class="introduce_item">中火提香，明前炒青绿茶</div>
          <div class="introduce_item">滋味浓醇，香高持久</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：37.5g(2.5g*15)</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 陈香普洱 -->
    <div class="item_box" v-if="pid == 8">
      <div class="series_title">高山系列</div>
      <div class="product_title">陈香普洱</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_chenxiangpuer.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：勐海县布朗山，</div>
          <div class="introduce_item">海拔：约1500米，</div>
          <div class="introduce_item">口感：陈香浓郁 ，浓醇回甘。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">千米海拔，高山茶园头春普洱</div>
          <div class="introduce_item">科学控温，近60天充分发酵熟茶</div>
          <div class="introduce_item">浓醇回甘，持久耐泡</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：37.5g(2.5g*15)</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 青心乌龙 -->
    <div class="item_box" v-if="pid == 9">
      <div class="series_title">高山系列</div>
      <div class="product_title">青心乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_qingxinwulong.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：云南腾冲高黎贡山，</div>
          <div class="introduce_item">海拔：约2000米，</div>
          <div class="introduce_item">口感：鲜爽甘醇，清新花果香。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">两千米海拔，采摘高山茶园初夏鲜叶</div>
          <div class="introduce_item">软枝乌龙品种，轻发酵轻焙火</div>
          <div class="introduce_item">鲜爽甘醇，清新花果香</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：37.5g(2.5g*15)</div>
        </div>
      </div>
    </div>

    <!-- 高山系列 蜜香红茶 -->
    <div class="item_box" v-if="pid == 10">
      <div class="series_title">高山系列</div>
      <div class="product_title">蜜香红茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_mixianghongcha.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">产地：云南腾冲高黎贡山，</div>
          <div class="introduce_item">海拔：约2000米，</div>
          <div class="introduce_item">口感：香高味醇 ，焦糖香浓。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">两千米海拔，采摘高山茶园初夏鲜叶</div>
          <div class="introduce_item">软枝乌龙品种，重焙火全发酵</div>
          <div class="introduce_item">香高味醇 ，焦糖香浓</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：37.5g(2.5g*15)</div>
        </div>
      </div>
    </div>

    <!-- 汉方养园系列 冬瓜荷叶 -->
    <div class="item_box" v-if="pid == 11">
      <div class="series_title">汉方养园系列</div>
      <div class="product_title">冬瓜荷叶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d_a.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">海南黑皮冬瓜，湖北晒干荷叶，</div>
          <div class="introduce_item">拼配决明子、橘皮、玫瑰花蕾，</div>
          <div class="introduce_item">收获滋养好茶。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">消油解腻，曲线动人香</div>
          <div class="introduce_item">六味食材，汉方调配</div>
          <div class="introduce_item">草本天然，轻肠轻体</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：75g(5gx15)</div>
        </div>
      </div>
    </div>

    <!-- 汉方养园系列 桂圆红枣 -->
    <div class="item_box" v-if="pid == 12">
      <div class="series_title">汉方养园系列</div>
      <div class="product_title">桂圆红枣</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d_b.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">云南后发酵普洱茶，</div>
          <div class="introduce_item">新疆红枣、福建桂圆、宁夏枸杞，</div>
          <div class="introduce_item">三宝一茶，清甜好味。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">创新配方，普洱打底</div>
          <div class="introduce_item">四味食材，无需妆容</div>
          <div class="introduce_item">甜润柔和，枣香浓郁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：75g(5gx15)</div>
        </div>
      </div>
    </div>

    <!-- 汉方养园系列 红豆薏米 -->
    <div class="item_box" v-if="pid == 13">
      <div class="series_title">汉方养园系列</div>
      <div class="product_title">红豆薏米</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d_c.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">黑龙江赤小豆，福建蒲城薏米，</div>
          <div class="introduce_item">搭配大枣、熟普洱，芡实，</div>
          <div class="introduce_item">天然食养，轻体除湿。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">普洱打底，利水除湿</div>
          <div class="introduce_item">清甜好味，轻盈体态</div>
          <div class="introduce_item">五味食材，远离湿态</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：75g(5gx15)</div>
        </div>
      </div>
    </div>

    <!-- 汉方养园系列 金桂雪梨 -->
    <div class="item_box" v-if="pid == 14">
      <div class="series_title">汉方养园系列</div>
      <div class="product_title">金桂雪梨</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d_e.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">福建福鼎贡眉与浙江毛峰，</div>
          <div class="introduce_item">拼配桂花、雪梨丁、白瓜粒，</div>
          <div class="introduce_item">止咳润肺，清热去火。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">白茶基底，去燥养身</div>
          <div class="introduce_item">甘甜花香，清爽茶味</div>
          <div class="introduce_item">五味食材，沁润身心</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：75g(5gx15)</div>
        </div>
      </div>
    </div>

    <!-- 汉方养园系列 菊花决明子 -->
    <div class="item_box" v-if="pid == 15">
      <div class="series_title">汉方养园系列</div>
      <div class="product_title">菊花决明子</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/hanfang_p_d_f.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">河南焦作胎菊，</div>
          <div class="introduce_item">安徽优质决明子，</div>
          <div class="introduce_item">融入枸杞、牛蒡根，</div>
          <div class="introduce_item">奉上鲜爽甜醇的茶味。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">清凉清心，顾盼生辉</div>
          <div class="introduce_item">五味食材，绿茶打底</div>
          <div class="introduce_item">清甜好味，熬夜必备</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：75g(5gx15)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 10口味组合装 -->
    <div class="item_box" v-if="pid == 16">
      <div class="series_title">组合系列</div>
      <div class="product_title">10口味组合装</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_a.jpg"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">体验10种口味尝鲜装，</div>
          <div class="introduce_item">轻松找到你喜欢的那款茶，</div>
          <div class="introduce_item">冷泡热泡随心畅饮。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">10种口味，一次体验</div>
          <div class="introduce_item">独立包装，即时随饮</div>
          <div class="introduce_item">携带方便，随手可拿</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：21g(3g+2gx9)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 21天打卡装 -->
    <div class="item_box" v-if="pid == 17">
      <div class="series_title">组合系列</div>
      <div class="product_title">21天打卡装</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_b.jpg"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">0糖0脂0卡，周期控糖，</div>
          <div class="introduce_item">为健身人士打造，奶茶级口感，</div>
          <div class="introduce_item">21天养成好习惯。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">0糖0脂0卡，周期控糖</div>
          <div class="introduce_item">奶茶级口感，好喝无负担</div>
          <div class="introduce_item">每日改变，21天养成好习</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：42g(2gx21)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 红茶大满贯 -->
    <div class="item_box" v-if="pid == 18">
      <div class="series_title">组合系列</div>
      <div class="product_title">红茶大满贯</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_c.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">他山集首款新年礼盒，</div>
          <div class="introduce_item">汇集世界五款精选红茶</div>
          <div class="introduce_item">打造有温度的女性礼物。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">世界五大经典红茶，一次品尝</div>
          <div class="introduce_item">首款红茶礼盒，暖胃养身</div>
          <div class="introduce_item">女性茶饮，传递“她力量”</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：60g(2gx30)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 企业量贩装 -->
    <div class="item_box" v-if="pid == 19">
      <div class="series_title">组合系列</div>
      <div class="product_title">企业量贩装</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_d.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">精选5种高山云雾好茶，</div>
          <div class="introduce_item">100袋超值企业量贩装，</div>
          <div class="introduce_item">满足企业办公、待客需求。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">100袋量贩，口粮常备</div>
          <div class="introduce_item">独立小包，冲饮方便</div>
          <div class="introduce_item">家庭饮用，企业待客</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：250g(2.5gx100)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 夏日果果茶 -->
    <div class="item_box" v-if="pid == 20">
      <div class="series_title">组合系列</div>
      <div class="product_title">夏日果果茶</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_e.jpg"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">白桃、葡萄、荔枝、柠檬、椰子，</div>
          <div class="introduce_item">集结5种水果风味，清香甜润，</div>
          <div class="introduce_item">给你夏日果味茶香味道。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">5种风味，缤纷果香</div>
          <div class="introduce_item">真实果粒，补充VC</div>
          <div class="introduce_item">原叶好茶，口感香醇</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：45g(3gx15)</div>
        </div>
      </div>
    </div>

    <!-- 组合系列 半夏冷萃 -->
    <div class="item_box" v-if="pid == 21">
      <div class="series_title">组合系列</div>
      <div class="product_title">半夏冷萃</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/zuhe_p_d_f.jpg"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">白桃乌龙、青心乌龙、茉莉绿茶、</div>
          <div class="introduce_item">玫瑰滇红、东方美人，集5种口味，</div>
          <div class="introduce_item">低温萃取，口感清凉甘甜。</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">5种口味，任意冰爽</div>
          <div class="introduce_item">原叶袋泡，健康方便</div>
          <div class="introduce_item">冷萃组合，冷热皆宜</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：45g(3gx15)</div>
        </div>
      </div>
    </div>

    <!-- 果味系列 白桃乌龙 -->
    <div class="item_box" v-if="pid == 22">
      <div class="series_title">果味系列</div>
      <div class="product_title">白桃乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guoyuan_p_d_a.jpg"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】[半发酵]福建铁观音</div>
          <div class="introduce_item">【配料】广东揭阳白桃、蜜渍出干</div>
          <div class="introduce_item">【口感】爽口甜沁、浓郁蜜桃香</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">原叶乌龙拼配蜜渍白桃</div>
          <div class="introduce_item">白桃香甜、茶味鲜爽</div>
          <div class="introduce_item">果香茶香完美融合</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：30g(3gx10)</div>
        </div>
      </div>
    </div>

    <!-- 果味系列 椰子乌龙 -->
    <div class="item_box" v-if="pid == 23">
      <div class="series_title">果味系列</div>
      <div class="product_title">椰子乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guoyuan_p_d_b.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】[半发酵]福建安溪西坪镇铁观音</div>
          <div class="introduce_item">【配料】广东下川岛烘烤椰肉干</div>
          <div class="introduce_item">【口感】椰奶香浓、茶味鲜醇</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">精选优质福建安溪铁观音</div>
          <div class="introduce_item">大块果粒、烘烤椰肉干</div>
          <div class="introduce_item">茶味鲜醇、椰香浓郁</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：30g(3gx10)</div>
        </div>
      </div>
    </div>
    <!-- 果味系列 葡萄乌龙 -->
    <div class="item_box" v-if="pid == 24">
      <div class="series_title">果味系列</div>
      <div class="product_title">葡萄乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/putaowulong_p_d_b.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】福建安溪铁观音</div>
          <div class="introduce_item">【配料】新疆饱满绿提、脱水烘干</div>
          <div class="introduce_item">【口感】酸甜可口、鲜爽回甘</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">优质铁观音拼配新疆绿提干</div>
          <div class="introduce_item">葡萄香溢、茶香回甘</div>
          <div class="introduce_item">香气交错、清醇甘甜</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：30g(3g×10)</div>
        </div>
      </div>
    </div>
    <!-- 花果系列 桂花乌龙 -->
    <div class="item_box" v-if="pid == 25">
      <div class="series_title">花果系列</div>
      <div class="product_title">桂花乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guihuawulong_p_d_b.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】福建安溪铁观音</div>
          <div class="introduce_item">【配料】广西金桂</div>
          <div class="introduce_item">【口感】淡雅甘冽/口颊留香</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">金桂馥郁、乌龙回甘</div>
          <div class="introduce_item">一杯桂花乌龙</div>
          <div class="introduce_item">清新口气、唇颊留香</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：25g(2.5g×10)</div>
        </div>
      </div>
    </div>
    <!-- 组合系列 桃花未央 -->
    <div class="item_box" v-if="pid == 26">
      <div class="series_title">组合系列</div>
      <div class="product_title">桃花未央</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/taohuaweiyang_p_d_b.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">他山集、天猫、盘子女人坊三方联名，</div>
          <div class="introduce_item">拟汉服形态茶包</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">首款他山集三方联名茶包</div>
          <div class="introduce_item">唐风小衣柜，汉服小茶包</div>
          <div class="introduce_item">穿盛世华服，享盛唐茶宴</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：30g(3gx10)</div>
        </div>
      </div>
    </div>
    <!-- 果味系列 蜜桃乌龙 -->
    <div class="item_box" v-if="pid == 27">
      <div class="series_title">果味系列</div>
      <div class="product_title">蜜桃乌龙</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_mitaowulong.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】[半发酵]福建铁观音</div>
          <div class="introduce_item">【配料】安徽砀山水蜜桃、糖渍出干</div>
          <div class="introduce_item">【口感】鲜甜桃香、清甜茶味</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">精选优质福建安溪铁观音</div>
          <div class="introduce_item">安徽砀山糖渍桃干</div>
          <div class="introduce_item">茶味甘醇、桃香四溢</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：30g(3gx10)</div>
        </div>
      </div>
    </div>
    <!-- 花果系列 玫瑰滇红 -->
    <div class="item_box" v-if="pid == 28">
      <div class="series_title">花果系列</div>
      <div class="product_title">玫瑰滇红</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_meiguidianhong.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】云南临沧滇红</div>
          <div class="introduce_item">【配料】法国路易十四墨红玫瑰</div>
          <div class="introduce_item">【口感】丝滑甜醇、花香四溢</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">路易十四墨红玫瑰浓香</div>
          <div class="introduce_item">云南大叶种茶树一芽二叶</div>
          <div class="introduce_item">滇红甜润、滋味甘冽</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：25g(2.5g×10)</div>
        </div>
      </div>
    </div>
    <!-- 花果系列 陈皮普洱 -->
    <div class="item_box" v-if="pid == 29">
      <div class="series_title">花果系列</div>
      <div class="product_title">陈皮普洱</div>
      <div class="product_img">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/v2/d_chenpipuer.png"
          alt=""
          srcset=""
        />
      </div>
      <div class="introduce">
        <div class="introduce_left">
          <div class="title">产品介绍</div>
          <div class="introduce_item">【茶叶】云南临沧滇红</div>
          <div class="introduce_item">【配料】五年新会陈皮</div>
          <div class="introduce_item">【口感】浓醇回甘、柑香馥郁</div>
        </div>
        <div class="introduce_mid">
          <div class="title">产品特性</div>
          <div class="introduce_item">甄选五年新会二红柑</div>
          <div class="introduce_item">新工艺发酵布朗山熟普洱</div>
          <div class="introduce_item">滋味浓醇、耐泡度高</div>
        </div>
        <div class="introduce_right">
          <div class="title">产品规格</div>
          <div class="introduce_item">净含量：25g(2.5g×10)</div>
        </div>
      </div>
    </div>
    <MobileConncatUs />
    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import MobileConncatUs from "@/components/MobileConncatUs";
export default {
  name: "TasanteaProductDetail",
  components: {
    MHeader,
    MFooter,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      pid: "1",
    };
  },
  methods: {
    goBack() {
      this.$router.push("/mtasantea/list");
    },
  },
  created() {
    this.pid = this.$route.query.pid;
  },
  mounted() {},
};
</script>

<style scoped lang="less">
#TasanteaProductDetail {
  width: 100vw;
  min-height: 100vh;
  background: #e9e9df;
  margin-top: 18.667vw;
  overflow: hidden;
  .logo {
    width: 24vw;
    height: 10.933vw;
    margin: 4.933vw auto 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .close {
    width: 5.333vw;
    height: 5.333vw;
    margin: 2.267vw auto 6.467vw auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .item_box {
    .series_title {
      font-size: 24px;
      font-weight: 500;
      color: #121212;
      line-height: 7.067vw;
      text-align: center;
    }
    .product_title {
      font-size: 18px;
      font-weight: 400;
      color: #121212;
      line-height: 7.067vw;
      margin: 2.667vw auto 4.533vw auto;
      text-align: center;
    }
    .product_img {
      width: 76.8vw;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    .introduce {
      width: 100vw;
      margin: 5.067vw auto;
      /* display: flex;
      justify-content: space-around; */
      overflow: hidden;
      .introduce_left,
      .introduce_mid,
      .introduce_right {
        /*  overflow: hidden; */
        /* width: 34.133vw; */
        float: left;
        margin-left: 4vw;
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #45B035;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          /* line-height: 50px; */
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
        }
      }
      .introduce_left {
        width: 35.133vw;
      }
      .introduce_mid {
        width: 34.133vw;
      }
      .introduce_right {
        width: 16vw;
      }
      /* .introduce_mid {
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #97c629;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          width: 24px;
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          line-height: 24px;
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
          span {
            padding-top: 8px;
          }
        }
      }
      .introduce_right {
        .title {
          width: 8.133vw;
          height: 23.867vw;
          background: #97c629;
          writing-mode: vertical-rl; //垂直方向，从左向右
          line-height: 8.133vw;
          letter-spacing: 5px;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          float: left;
        }
        .introduce_item {
          font-size: 13px;
          font-weight: 400;
          color: #121212;
          line-height: 24px;
          writing-mode: vertical-rl; //垂直方向，从左向右
          letter-spacing: 5px;
          float: left;
          margin-left: 1.333vw;
        }
      } */
    }
  }
}
</style>
